import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

import { httpInterceptorProviders } from './http-interceptor/interceptor';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { SharedDialogComponent } from './components/dialog/dialog.component';
import { LoaderComponent } from './components/loader-component/loader.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { SiteNavigationComponent } from './components/site-navigation/site-navigation.component';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    ErrorCodeComponent,
    SharedDialogComponent,
    LoaderComponent,
    SiteHeaderComponent,
    SiteNavigationComponent,
  ],
  imports: [CommonModule, MaterialModule, RouterModule, FormsModule],
  exports: [
    CommonModule,
    RouterModule,
    ErrorCodeComponent,
    SharedDialogComponent,
    LoaderComponent,
    SiteHeaderComponent,
    SiteNavigationComponent,
  ],
  providers: [httpInterceptorProviders],
})
export class SharedModule {}
